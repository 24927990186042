import React from 'react';
import { Helmet } from 'react-helmet';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

export const Osano = () => {
  if (!gatsbyConfig?.osano) return null;

  return (
    <Helmet>
      <script src={`https://cmp.osano.com/${gatsbyConfig.osano.customerId}/${gatsbyConfig.osano.configId}/osano.js`} />
    </Helmet>
  );
};

Osano.propTypes = {};

Osano.defaultProps = {};
